import React from "react"
import NoheaderFooter from "../../../../components/Noheaderfooter"
import SEO from "../../../../components/seo"
import DebitCardInsuranceMobile from "../../../../components/body/pages/mobile/ng/legal/debitCards"

const DebitCardsInsurancePage = () => (
  <NoheaderFooter>
    <SEO
      canonical={"https://kuda.com/en-ng/legal/cards"}
      title="Debit Card - Terms and Conditions"
      description="Kuda Debit Card Legal Terms and Policies"
    />
    <DebitCardInsuranceMobile />
  </NoheaderFooter>
)

export default DebitCardsInsurancePage
